<template>
    <div>
        <el-dialog
            v-model="dialogVisible"
            :title='title'
            width="350px"
        >
            <el-form v-loading='isLoading'>

                <el-form-item :label="lang['form.money']">
                    <el-input v-model="form.money" type="number"/>
                </el-form-item>

                <el-form-item :label="lang['form.moneyType.label']">
                    <el-radio-group v-model="form.moneyType">
                        <!--                    [额度类型] 1 = (余额)注册 2 = (余额)充值-->
                        <el-radio :label="1">{{lang['form.moneyType.1']}}</el-radio>
                        <el-radio :label="2">{{lang['form.moneyType.2']}}</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item :label="lang['form.info.label']">
                    <el-tag type="info">
                        {{lang['form.info.reg']}}:{{agentInfo.moneyReg}}
                    </el-tag>

                    <el-tag type="info" style="margin-left: 10px">
                        {{lang['form.info.rec']}}:{{agentInfo.moneyRec}}
                    </el-tag>
                </el-form-item>

                <el-form-item :label="lang['form.changeType.label']">
                    <el-radio-group v-model="form.changeType">
                        <!--                    [操作类型] 1 = 充值下级 2 = 回收下级-->
                        <el-radio :label="1">{{lang['form.changeType.addMoney']}}</el-radio>
                        <el-radio :label="2">{{lang['form.changeType.recovery']}}</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>

            <template #footer>
                <el-button @click="submit">{{lang['form.submit']}}</el-button>
                <el-button @click="this.dialogVisible = false">{{lang['form.return']}}</el-button>
            </template>
        </el-dialog>
    </div>

</template>

<script>
export default {
    name: "agentList-changeMoney",
    emits:['updateTable'],
    data(){
        return {
            username:'',
            dialogVisible:false,
            title:'Money',
            form:{
                agentId:0,
                changeType:0,//[操作类型] 1 = 充值下级 2 = 回收下级
                money:null,//操作的金额
                moneyType:1,//[额度类型] 1 = (余额)注册 2 = (余额)充值
            },
            isLoading:true,
            agentInfo:{
                moneyReg:-1,
                moneyRec:-2,
            },
            lang:this.$lang.getLang('agent','agentListChangeMoney'),
        }
    },
    methods:{
        /**
         * 打开筛选器
         * @param agentId
         * @param username
         * @param changeType [操作类型] 1 = 充值下级 2 = 回收下级
         */
        openDialog(agentId,username,changeType){
            this.form = {
                    agentId:0,
                    changeType:0,//[操作类型] 1 = 充值下级 2 = 回收下级
                    money:null,//操作的金额
                    moneyType:1,//[额度类型] 1 = (余额)注册 2 = (余额)充值
            };
            if (agentId<=0){
                this.$alert('AgentId Error',null,{type:'error'});
                return;
            }
            this.isLoading = true;
            this.title = this.lang['openDialog.user'] + username + '[' + agentId + ']';
            this.username = username, this.dialogVisible = true,
                this.form.agentId = agentId,this.form.changeType = changeType;
            this.getAgentMoney();
        },
        //查询代理商最新余额
        getAgentMoney(){
            this.$api.post('Agent.Money/getAgentMoney',{agentId:this.form.agentId}).then((res)=>{
                let data = this.$helper.checkRes(res,true);
                if (data){
                    this.agentInfo = {
                        moneyRec: data.money_rec,
                        moneyReg: data.money_reg
                    };
                }
                this.isLoading = false;
            }).catch(error=>{
                    this.$helper.axiosCatch(error)
                    this.isLoading = false;
                });
        },
        //提交请求
        submit(){
            let int = this.form.money;
            if (int <=0 || int >= 99999){
                this.$alert(this.lang['submit.error.money']);
                return;
            }
            this.isLoading = true;
            this.$api.post('Agent.Money/changeMoney',this.form).then(res=>{
                this.isLoading = false;
                let data = this.$helper.checkRes(res);
                if (data){
                    //执行成功
                    //重新查询代理商最新余额
                    // this.getAgentMoney();
                    this.$alert(this.lang['submit.succ'],null,{type:'success'});
                    //执行成功,通知父组件强制更新表格数据
                    this.$emit('updateTable',{});
                }else {
                    let code = this.$helper.getResErrorCode(res);
                    let field = 'submit.code.' + code;
                    let errMsg = this.lang[field] ?? this.lang['submit.code.un'];
                    this.$alert(errMsg,'Error Code:' + code,{type:'error'});
                }
                console.log(res,data)
            }).catch((error)=>{
                this.isLoading = false;
                this.$helper.axiosCatch(error);
            })
        }
    }
}
</script>

<style scoped>

</style>